import React from 'react'
import Layout from '../components/Layout'

const PrivacyPolicy = () => {
    return (
        <Layout>
            <section className="privacy">
                <h1 class="title article__title space is-5">Privacy Policy</h1>
                <p>Last Updated on 4/10/2022</p>

                <p>
                    NOTICE: Please read the Privacy Policy set forth below carefully, as
                    it is designed to provide important information on how and why we
                    collect, use, store and share your personal information. It also
                    outlines the rights you can exercise regarding your personal
                    information and how you can contact us if you have any questions or
                    complaints.
                </p>

                <p>
                    The Privacy Policy set forth below is legally binding. By visiting,
                    viewing or using this website and/or by using any program, product,
                    course or service from us, you agree to be bound by this Privacy
                    Policy.
                </p>

                <p>
                    Your privacy is important to jennyq.co (“website”), which is operated
                    by Jennifer Quispe (“Company”, “we”, “us”, or “our”).
                </p>

                <p>
                    We use the term “personal information” to refer to data we collect
                    that may, directly or indirectly, identify, describe, relate to or be
                    associated with you. This privacy policy (“Privacy Policy”) applies to
                    personal information we collect when you interact with us through
                    different means, including by visiting and using our website. The term
                    “you” refers to any visitor, viewer or user of the website and/or any
                    user of any Product. Please note that we cannot control the privacy
                    practices of websites and services that we do not own.
                </p>
                <p>
                    Please read the entire Privacy Policy before you visit or use the
                    website or perform any Actions (as defined below). By visiting the
                    website or performing any Actions (as defined below), you consent to
                    the terms of this Privacy Policy.
                </p>

                <p>
                    {' '}
                    INFORMATION WE COLLECT AND HOW WE COLLECT IT The following describes
                    the categories of personal information we collect and how we collect
                    such information.
                </p>

                <p>
                    {' '}
                    Information You Provide. We collect information you provide to us
                    directly. This includes information you provide when you (i) receive
                    any free or purchase any paid programs, products, courses or services
                    from us (each, a “Product”), (ii) sign up to receive any emails, (iii)
                    comment on any posts or otherwise communicate with us on any social
                    media platform, (iv) register for presentations or classes, (v) fill
                    out any forms, (vi) access public or private membership groups,
                    including those hosted via a third-party platform (i.e., Facebook),
                    (vii) sign up to become our affiliate partner, (viii) respond to any
                    survey, (ix) participate in any contest or sweepstakes, or (x) contact
                    us through any other means, including via an online form, phone call,
                    or email (collectively, the “Actions”).
                </p>

                <p>
                    {' '}
                    Examples of data we may receive include your first name, last name,
                    telephone number, email address, shipping address, billing address,
                    physical address (such as your address, state, province, ZIP/postal
                    code and city), date of birth, gender, account name, billing
                    information (such as your credit card number), financial information,
                    Social Security Number, Tax Identification Number, Employer
                    Identification Number, PayPal address, social media information, and
                    other information you provide to us through survey responses,
                    feedback, reviews and other means of communication.
                </p>

                <p>
                    {' '}
                    Information Collected Automatically. We collect some data
                    automatically when you visit or use our website or open or respond to
                    our emails. For example, we may automatically collect information when
                    you open or respond to our emails, make a choice with respect to
                    communications we send to you, visit any page that displays our
                    content, provide information to our service providers or purchase or
                    return a Product.
                </p>

                <p>
                    {' '}
                    Information from Third Party Sites. We collect some data when you
                    connect with us, comment on or like our posts or otherwise interact
                    with us on any social media platform, or when you access public or
                    private membership groups hosted on a third party platform (i.e.,
                    Facebook). Examples of data we may receive include your profile
                    information, profile picture, social media information, social media
                    handles or nicknames, name, purchase history, email address, device
                    identifiers and demographic information.
                </p>

                <p>
                    {' '}
                    Information from Internet or Other Electronic Network Activity. We
                    automatically collect some data about your computer or mobile device
                    when you access our website. Examples of data we may receive include
                    your Internet Protocol (“IP”) address, browser type, browser version,
                    cookies from your browser, unique device identifiers, web browser
                    software (i.e., Google Chrome), information about the referring
                    website, the date, time and length of your visit, including the
                    specific pages you visit, information on how you interact with the
                    website, Products and tools and other diagnostic data. Examples of
                    additional data we may receive when you access our website through a
                    mobile device include the type of mobile device you are using, the
                    unique mobile device ID, your mobile operating system, web browser
                    software on mobile, unique device identifiers and other diagnostic
                    data.
                </p>

                <p> PURPOSES FOR COLLECTING INFORMATION</p>
                <p>
                    We use your information for business and commercial purposes. For
                    example, we may use your information to:
                </p>
                <p> Register you for a course, presentation or class.</p>
                <p>
                    {' '}
                    Grant you access to a public or private membership group or other
                    account, and maintain and service your profiles for such accounts.
                </p>
                <p>
                    {' '}
                    Validate and authenticate your profile when logging into a public or
                    private membership group or other account or when purchasing a
                    Product.
                </p>
                <p> Analyze interactions with you to improve quality.</p>
                <p> Identify your product preferences and shopping preferences.</p>
                <p> Secure our website and data.</p>
                <p> Create Products that you are interested in.</p>
                <p>
                    {' '}
                    Ship or otherwise deliver, process payment for, communicate about, and
                    track orders of any Products.
                </p>
                <p>
                    {' '}
                    Suggest Products you may like based on past purchases and otherwise
                    personalize your experience with the website.
                </p>
                <p>
                    {' '}
                    Provide promotional and marketing communications and information if
                    you elect to receive it, including email marketing.
                </p>
                <p>
                    {' '}
                    Improve the design, functionality and ease-of-use of our website and
                    Products.
                </p>
                <p>
                    {' '}
                    Respond to any inquiries, reviews or other feedback you submit to us.
                </p>
                <p> Provide customer service.</p>
                <p> Conduct research to improve our business processes.</p>
                <p> Administer affiliate programs.</p>
                <p> Administer contests, sweepstakes, surveys or promotions.</p>
                <p>
                    {' '}
                    Administer any business needs related to your purchase of any
                    Products.
                </p>
                <p>
                    {' '}
                    Detect security incidents and protect against, stop, resolve and
                    prevent any fraud and fraudulent transactions and any malicious,
                    deceptive or illegal activity.
                </p>
                <p> Comply with all applicable law.</p>
                <p>
                    {' '}
                    Respond to legal and regulatory inquiries and assist law enforcement.
                </p>
                <p> THIRD PARTIES WE SHARE INFORMATION WITH</p>
                <p>
                    {' '}
                    The following is a list of third parties that we may share your
                    information with or for.
                </p>

                <p>
                    {' '}
                    Affiliate Partners. We may share your information with affiliate
                    partners to generate traffic or leads or for other business purposes.
                </p>

                <p>
                    Analytics Providers. We may share your information with analytics
                    providers.
                </p>

                <p>
                    Business Transfers. If we and/or our website are acquired by a third
                    party as a result of a transfer, sale, merger, acquisition,
                    reorganization, liquidation, consolidation, merger or sale of some or
                    all of our Company and/or our website, your personal information may
                    be a transferred asset. We may also share personal information to
                    prospective purchasers to diligence the proposed transaction.
                </p>

                <p>
                    {' '}
                    Cloud Service Providers. We may share your information with cloud
                    service providers.
                </p>

                <p>
                    {' '}
                    Customer Analysis Providers. We may share your information with
                    customer analysis providers, such as those used to analyze visitors
                    clicks and navigation around the website.
                </p>

                <p>
                    {' '}
                    Customer Service Providers. We may share your information with
                    customer service providers.
                </p>
                <p>
                    {' '}
                    Delivery Partners. We may share your information with delivery
                    partners, such as those we use to ship or otherwise deliver any
                    Products.
                </p>

                <p>
                    {' '}
                    Email Marketing and Advertising Providers. We may share your
                    information with marketing and advertising providers, including email
                    marketing and campaign providers, marketing software providers, direct
                    mail providers, marketing analytics providers and sales funnel
                    providers. We may share your information with email marketing service
                    providers, in order to send you emails, newsletters, promotional
                    materials, marketing materials or other information.
                </p>

                <p>
                    Fraud Prevention Partners. We may share your information with fraud
                    prevention partners.
                </p>

                <p>
                    {' '}
                    Fulfillment Partners. We may share your information with fulfillment
                    partners, such as those we use to fulfill any Products.
                </p>

                <p>
                    {' '}
                    Government Agencies. We may share your information with government
                    agencies, courts, regulators and law enforcement in the event we are
                    required to comply with applicable laws and regulations or a legally
                    binding process, or in response to subpoenas, warrants, government
                    inquiries or investigations, and court orders. We may also share your
                    information (i) to establish, exercise, protect or enforce our legal
                    rights and the legal rights of our agents, employees and affiliates,
                    (ii) to defend against a legal claim, (iii) to protect you, us or
                    third parties against injury, interference, fraud or harm or (iv) to
                    take action related to violations of our policies, including this
                    Privacy Policy and our Terms & Conditions, or potentially illegal
                    activities.
                </p>

                <p>
                    {' '}
                    Other Service Providers. We may share your information with service
                    providers.
                </p>
                <p>
                    Payment Processors. We may share your information with payment
                    processors, such as those we use to collect and process payment for
                    any Products you purchase.
                </p>
                <p>
                    Public Forum. Our website may allow you to leave a post, comment or
                    review on the website. If you choose to submit that information on a
                    public forum, that information will be available to the public and we
                    may elect to share your post, comment or review outside of the
                    website.
                </p>
                <p>
                    Social Media Platforms. We may share your information with social
                    media platforms (i.e., Facebook). Their use of your information is not
                    governed by this Privacy Policy.
                </p>
                <p>
                    Technology Service Providers. We may share your information with
                    technology service providers.
                </p>
                <p>
                    Third Parties. We may share your information with third parties whom
                    we have contractual relationships with, such as auditors, consultants,
                    lawyers, and other professionals who rely on the data to provide us
                    with professional services.
                </p>
                <p>
                    Third Party Partners. We may share your information with third parties
                    we have partnered with to jointly create or offer a product, service
                    or joint promotion.
                </p>

                <p>WE DO NOT SELL YOUR PERSONAL INFORMATION</p>
                <p>
                    We do not sell, rent or trade your personal information to any third
                    parties, as we value your privacy. We also do not “sell” your personal
                    information as defined by the California Consumer Privacy Act.
                </p>

                <p> COOKIES AND OTHER TRACKING TECHNOLOGIES</p>
                <p>
                    {' '}
                    We may collect and access, and may permit our business partners and
                    third party service providers, such as advertising companies, to
                    collect and access your Internet Protocol (IP) address, browsing
                    metadata and other numerical identifiers, such as your browser type,
                    version and operating system (collectively, the “Browsing
                    Information”). We may also use, place, collect and store, or allow our
                    third party service providers to use, place, collect and store,
                    cookies, web beacons, remarketing pixel tags, or other similar
                    tracking technologies.
                </p>

                <p>
                    {' '}
                    We, our business partners and third party service providers may use
                    this information and these technologies to, among other things,
                    improve and personalize your user experience, understand how you use
                    the website, provide tailored ads, analyze trends, data, and website
                    performance, administer the website, identify and track you when you
                    use different devices, determine if you are logged onto the website,
                    provide security and provide a range of features, customization and
                    functionality.
                </p>

                <p>
                    {' '}
                    By using the website and not opting out of cookies, you consent to the
                    use of Browsing Information, cookies and other tracking technologies
                    as described in this Privacy Policy. Note that we have no control over
                    these third party service providers and their use of such tracking
                    technologies. We cannot and do not control the privacy policies and
                    practices of any third party service providers. We encourage you to
                    visit their websites directly to learn about their privacy policies.
                </p>

                <p> YOUR CHOICES</p>
                <p>
                    {' '}
                    It is important to us that you understand your choices regarding your
                    personal information.
                </p>
                <p>
                    Opting Out of/Blocking Cookies. Most browsers accept cookies by
                    default. However, most browsers will allow you to prevent accepting
                    new cookies, disable cookies, and/or receive a notification when you
                    receive new cookies. If your browser has such functionalities,
                    information on how you can change your cookie settings can typically
                    be found in the help section of the browser toolbar. Please note that
                    if you do disable cookies, this may have an impact on or interfere
                    with your user experience, including your ability to use or make
                    purchases from the website, or receive personalized content.
                </p>
                <p>
                    Managing “Do Not Track”. As required by the California Online Privacy
                    Protection Act (CalOPPA), we would like you to know that our systems
                    are currently unable to recognize browser “Do No Track” signals.
                </p>
                <p>
                    Use of Personal Information. You can request that we delete your
                    personal information at any time by contacting us using the contact
                    details provided below, subject to certain exceptions. You can also
                    (i) request to see your personal information that we have available on
                    you, (ii) withdraw consent for our use of your personal information,
                    (iii) review and request we rectify, change or modify your personal
                    information, (iv) restrict or limit the processing of your personal
                    information, (v) cancel the processing of your personal information
                    and (vi) request your personal information and transfer it to another
                    controller without any impediments on our part by contacting us using
                    the contact details provided below.
                </p>
                <p>
                    Unsubscribing from Email Marketing. When you sign up to receive emails
                    from us, you agree to receive email communications from us, which may
                    include newsletters and promotional emails. If you receive any email
                    marketing from us, you can opt out at any time by clicking the
                    “unsubscribe” link contained in each email. Please note that
                    unsubscribing from email marketing does not necessarily unsubscribe
                    you from other emails we may send, such as emails about any Products
                    you purchase.
                </p>
                <p>
                    Other Opt-Out Options. If we send you physical mailings or short
                    message service (SMS) messages or contact you via telephone, you can
                    opt-out by contacting us using the contact details provided below.
                </p>

                <p>DATA RETENTION</p>
                <p>
                    {' '}
                    We retain personal information as long as it is needed to conduct and
                    operate our business or until you ask us to delete your personal
                    information by contacting us using the contact details provided below.
                    Please note that we cannot control the data retention policies of
                    third parties.
                </p>
                <p>THIRD PARTY LINKS</p>
                <p>
                    {' '}
                    For your convenience, we provide links to third party websites on our
                    website, such as links to third party social media platforms. If you
                    click on a link to a third party website, you will be directed to a
                    third party website. We cannot and do not control the privacy
                    policies, content and practices of the website owners and operators of
                    any of the third party websites that we link to. We encourage you to
                    visit their websites directly to learn about their privacy policies.
                </p>
                <p>HOW WE PROTECT YOUR PERSONAL INFORMATION</p>
                <p>
                    We care about the security of your personal information, so we
                    maintain reasonable and appropriate physical, technical and procedural
                    safeguards to help keep it safe. While we take reasonable steps to
                    protect your personal information, no method of transmission over the
                    internet or other network can be 100% secure, therefore we cannot and
                    do not guarantee that personal information you transmit will remain
                    secure from misuse or interception in all circumstances. By consenting
                    to this Privacy Policy, you acknowledge that we cannot guarantee that
                    your personal information will be protected from misuse or
                    interception by third parties.
                </p>
                <p> INTERNATIONAL DATA, TRANSFERS AND PROCESSING</p>
                <p>
                    Our website is intended for individuals located within United States.
                    Please be aware that our website servers and our service providers may
                    be located outside of your state, province or country. As a result,
                    some of your personal information may be collected, used, transferred,
                    maintained, disclosed and stored outside of your state, province or
                    country. By using this website, you acknowledge and agree that the
                    collection, use, transfer, maintenance, disclosure and storage of your
                    personal information, Browsing Information and communications related
                    to or arising out of your use of this website is governed by the
                    applicable laws in the United States. While we have the appropriate
                    safeguards in place, the applicable privacy laws in the United States
                    may be less stringent than those of your state, province or country.
                </p>
                <p>NOTICE ABOUT THE GENERAL DATA PROTECTION REGULATION (GDPR NOTICE)</p>
                <p>
                    The information in this section, under the subheading “Notice About
                    the General Data Protection Regulation”, applies to individuals
                    covered by the General Data Protection Regulation (“GDPR”). References
                    to “you” and “your” in this section refer only to those covered by
                    GDPR. GDPR, which took effect on May 25, 2018, provides privacy rights
                    for those inside the European Economic Area.
                </p>
                <p>
                    For the purposes of this section, “personal information” refers to any
                    information relating to an individual which can be directly or
                    indirectly used to identify such individual. Examples include first
                    name and last name, email address, identification number, information
                    about location, ethnicity, gender, biometric data, web cookies, and
                    religious or political beliefs.
                </p>
                <p>
                    Legal basis for processing information. We may process personal
                    information under the following conditions: (i) we have received your
                    consent to process your personal information for one or more specific
                    purposes, (ii) processing of your personal information is necessary to
                    perform a contract to which you are a party to, or in order to take
                    steps at your request prior to entering into a contract, (iii)
                    processing of your personal information is necessary to comply with a
                    legal obligation we are subject to, (iv) processing of your personal
                    information is necessary in order to protect the vital interests of
                    you or another natural person, (v) processing of your personal
                    information is necessary to perform a task carried out in the public
                    interest or to exercise an official authority vested in us; (vi)
                    processing of your personal information is necessary for the purposes
                    of the legitimate interests pursued by us or a third party, except in
                    certain circumstances where the need for the information is overridden
                    by the need to protect the subject of the personal information (such
                    as when the subject of the personal information is a child).
                </p>
                <p>
                    We are happy to let you know which legal basis applies to the
                    processing of your personal information.
                </p>
                <p>
                    You have certain rights you can exercise under the GDPR, including the
                    following. Please note that this summary is merely provided for your
                    convenience, but we do not warrant the accuracy or exhaustiveness of
                    this summary nor should you rely on this as an accurate or exhaustive
                    list of your rights.
                </p>

                <p>
                    Right to Access. You have the right to learn whether or not your
                    personal information is being processed. If it is being processed, you
                    have the right to access the personal information, and to learn
                    certain information about your personal information, including: (i)
                    why it is being processed, (ii) the categories of personal information
                    we collected, (iii) the recipients or categories of recipients to whom
                    we have or will disclose the personal information to, (iv) if
                    possible, the amount of time we will store the personal information,
                    or if not possible, the criteria we use to determine such period and
                    (v) available information about the sources for personal information
                    we collected. Right to Correction. You have the right to correct any
                    inaccurate personal information about yourself. You also have the
                    right to complete any incomplete personal information collected,
                    including through providing an additional statement.
                </p>

                <p>
                    Right to Be Forgotten. You have the right to ask us to erase your
                    personal information, which we will do without undue delay under
                    certain circumstances. Examples may include: (i) where the personal
                    information is no longer necessary for the purposes for which it was
                    collected, (ii) where you withdraw consent on the basis of which we
                    processed your personal information, and there is no legal ground for
                    processing such personal information, (iii) where you invoke your
                    right to object (described below) and there are no overriding grounds
                    for processing such personal information, (iv) where your personal
                    information has been unlawfully processed and (v) where the personal
                    information has to be erased to comply with a legal obligation.
                </p>
                <p>
                    Right to Restrict Processing. You have the right to restrict the
                    processing of your personal information under certain circumstances.
                    Examples may include: (i) where you indicate the inaccuracy of your
                    personal information, (ii) where the processing is unlawful but you
                    would like the processing of your personal information to be
                    restricted as opposed to erased, (iii) where we no longer need the
                    personal information for processing, but you would like it restricted
                    for a legal basis, and (iv) where you invoke your right to object
                    (described below).
                </p>
                <p>
                    Notification of Recipients of Personal Information. If you exercise
                    your Right to Rectification, Right to Be Forgotten or Right to
                    Restrict Processing (each as described above), we will convey that to
                    each recipient we have shared your personal information with. You have
                    the right to request we provide you with a list of all recipients we
                    have notified.
                </p>
                <p>
                    Right to Data Portability. You have the right to request your personal
                    information in a structured, commonly used and machine-readable
                    format.
                </p>
                <p>
                    Right to Object. You have the right to object to the processing of
                    your personal information under certain circumstances. Examples may
                    include: (i) where the personal information is being processed on
                    grounds relating to your personal situation, where the legal grounds
                    for processing such personal information falls under categories (v)
                    and (vi) as described in the sub-section titled “Legal basis for
                    processing information” and (ii) where the personal information is
                    processed for direct marketing purposes.
                </p>
                <p>
                    Right to Lodge a Complaint. You have the right to lodge a complaint
                    with a supervisory authority. For more information, please contact
                    your local data protection authority. Right to Be Informed About
                    International Transfers. You have the right to be informed about the
                    international transfer of your personal information and safeguards in
                    place. Please read the section of this Privacy Policy titled
                    “International Data, Transfers and Processing”.
                </p>
                <p>
                    Right To Object to Profiling. We may use automated decision-making in
                    operating our website. You have the right not to be subjected to any
                    decisions arising from automated processing, including profiling,
                    which produces legal effects concerning you or similarly significantly
                    affects you.
                </p>

                <p>
                    To exercise any of your rights, please contact us using the contact
                    details provided below. We are required to verify the identity of
                    anyone who makes any request.
                </p>

                <p>NOTICE TO CALIFORNIA RESIDENTS (CCPA NOTICE)</p>
                <p>
                    The information in this section, under the subheading “Notice to
                    California Residents”, applies to residents of California. References
                    to “you” and “your” in this section refer only to residents of
                    California. The California Consumer Protection Act (“CCPA”), which
                    took effect on January 1, 2020, provides privacy rights for residents
                    of California. Please refer to the referenced sections in this Privacy
                    Policy for details on the following, which you have a right to know
                    under the CCPA:
                </p>
                <p>
                    To review information on the categories of personal information and
                    the categories of sources from which we collect, use, store and share
                    personal information, read the section of this Privacy Policy titled
                    “Information We Collect and How We Collect It”.
                </p>
                <p>
                    To review information on the business or commercial purposes for which
                    we collect information, read the section of this Privacy Policy titled
                    “Purposes for Collecting Information”.
                </p>
                <p>
                    To review information on the categories of third parties we share
                    personal information, read the section of this Privacy Policy titled
                    “Third Parties We Share Information With”.
                </p>

                <p>
                    As a California resident, you have certain rights you can exercise
                    under the CCPA, including the following. Please note that this summary
                    is merely provided for your convenience, but we do not warrant the
                    accuracy or exhaustiveness of this summary nor should you rely on this
                    as an accurate or exhaustive list of your rights.
                </p>
                <p>
                    Right to Know. You have the right to obtain from us certain
                    information about our collection of your personal information over the
                    past 12 month period, including: (i) the categories of personal
                    information we collected, (ii) the specific pieces of your personal
                    information we collected, (iii) the categories of sources for the
                    personal information we collected, (iv) our business or commercial
                    purpose for collecting or selling that personal information, (v) the
                    categories of third parties with whom we share that personal
                    information, and (vi) if we sold or disclosed your personal
                    information, then (a) a list disclosing the categories of personal
                    information involved in sales and the categories of third parties to
                    whom we sold your personal information and (b) a list disclosing
                    categories of personal information disclosed for a business purpose
                    and the categories of third parties with whom we shared your personal
                    information. You have the right to obtain this information from us,
                    free of charge, twice each year.
                </p>
                <p>
                    Right to Delete. You have the right to request that we delete your
                    personal information that we have collected, subject to certain
                    exceptions. When you contact us, please let us know the information
                    you are requesting we remove, update, correct or amend, and the
                    timeframe and manner in which you believe we came to collect such
                    information.
                </p>
                <p>
                    Right to Opt-Out. You have the right to opt-out of the sale of your
                    personal information. We do not sell, rent or trade your personal
                    information to any third parties, as we value your privacy. Therefore,
                    no request to opt-out of sale is necessary.
                </p>
                <p>
                    Right to Non-Discrimination. You have the right not to receive
                    discriminatory treatment by us for exercising your rights under the
                    CCPA. We will not discriminate against you in any way for exercising
                    any of the rights available to you under the CCPA, including by
                    denying you goods or services, charging you different prices or rates,
                    providing you with a lesser quality of goods or services or suggesting
                    that you would receive a different price or rate for goods or services
                    or a different quality of goods or services.
                </p>
                <p>
                    {' '}
                    To exercise any of your rights, please contact us using the contact
                    details provided below. We are required to verify the identity of
                    anyone who makes any request.
                </p>

                <p>
                    NOTICE TO CALIFORNIA RESIDENTS (California’s “Shine the Light” Law)
                </p>
                <p>
                    We do not share your personal information with third parties who we
                    know or have reason to know may use your personal information for
                    “direct marketing purposes” as contemplated by California’s “Shine the
                    Light” law (Civil Code Section § 1798.83).
                </p>

                <p>NOTICE TO NEVADA RESIDENTS</p>
                <p>
                    We do not exchange your personal information for monetary
                    consideration with anyone who will license or sell your personal
                    information to third parties.
                </p>

                <p>CHILDREN’S PRIVACY</p>
                <p>
                    This website is not directed towards or designed for use by children
                    under the age of 18. This website and the information contained on it
                    is specifically designed for individuals over the age of 18. If you
                    are under the age of 18, you must not access this website or perform
                    any of the Actions. We do not knowingly collect, use, store or share
                    personal information from children under the age of 18. If you know or
                    have reason to believe that we have collected data from anyone under
                    the age of 18, please contact us using the contact details provided
                    for removal of that data.
                </p>

                <p>CHANGES TO THE PRIVACY POLICY</p>
                <p>
                    We reserve the right to change, amend or modify this Privacy Policy at
                    any time. We will alert you to any changes by posting the effective
                    date of the latest version at the top of this page, at which point any
                    changes will become immediately effective. It is your responsibility
                    to check for updates, as your continued use of the website and our
                    Products after the Privacy Policy is amended will constitute your
                    acceptance and agreement to continue to be bound by the Privacy Policy
                    as of the last updated date indicated at the top of this page.
                </p>

                <p>ALTERNATIVE FORMS</p>
                <p>
                    You may print this Privacy Policy by using the “Print” function on
                    your browser.
                </p>

                <p>HOW TO CONTACT US</p>
                <p>
                    If you have any questions or complaints related to this Privacy
                    Policy, or would like to exercise any of your rights, please contact
                    us using the information below.
                </p>
                <p>Email - adaux @ protonmail.com</p>
            </section>
        </Layout>
    )
}

export default PrivacyPolicy